import React, { useState} from 'react'
import * as BS from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'
import { firestore } from "../../utils/firebase"
import { navigate } from 'gatsby-link'
import { useQueryParam, StringParam } from "use-query-params";
import cats from '../data/bankbank/cat.json'


const BankBank = (props) => {
//   const { firebase } = useContext(FirebaseContext);
  const [email, setEmail] = useState('')
  const [sent] = useState(false)
  const [message] = useState('')
 
  const changeEmailHandler = (event) => {
    setEmail(event.target.value)
  }

  const [cat, setCat] = useQueryParam("cat", StringParam);
  console.log('cat', cat)
  // // const db = firebase.firestore();

  let ca = cats.find(Category => Category.Url === cat)
  console.log('ca', ca)
  let categ = ''
  
  if(cats.find(Category => Category.Url === cat)){
    categ = ca['Category']
  }


  

  const addToDB = async () => {
    firestore.collection('bankbank').add({
      email: email,
      date: new Date(),
      tag: categ || 'notag',
    })
    console.log('it worked!!')
  }

  // useEffect(() => {
  //   localStorage.setItem('email', JSON.stringify(email))
  // }, [email])

  const handleSubmit = e => {
    e.preventDefault();
    // setMessage('Thank you for joining! Please check your inbox for your #1 spreadsheet')
    if(email !== '' ){
      // addToMailchimp(email) // listFields are optional if you are only capturing the email address.
      // .then(data => {console.log(data)})
      // .catch(() => {})
      addToDB().then(()=> {
        console.log('it really is')
        navigate('/merci/')
        // window.location.href = '/thanks/dark-kitchen/'

      })
     
      
    }
  }
  
  const [shake, setShake] = useState(true);
    
  const animate = () => {
      
    // Button begins to shake
    setShake(true);
    
    // Buttons tops to shake after 2 seconds
    setTimeout(() => setShake(false));
    
}

var d = new Date();
var day = d.getDate();
var month = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
var n = month[d.getMonth()];
var y = d.getFullYear();


    return (
      <> 
      <BS.Container style={{marginTop: 20, marginBottom: 80}}>
      <div className="Newsletter">
    
          <h2>{message}</h2>
   
          {sent ?
          <div></div> :
          <div className="form">
            <form className="subscribe" onSubmit={handleSubmit}>
              
            <BS.Form.Group>
            <h1 style={{textAlign:'center', color: '#1c2d35', fontSize: 55, fontWeight: 800}}>Vous cherchez <span style={{textDecoration: 'underline', color: '#1c2d35'}}>une nouvelle banque?</span> 🏢 </h1>
            <h1 style={{textAlign:'center', color: '#1c2d35', fontSize: 55, fontWeight: 800}} id='mobileHide'><span style={{textDecoration: 'none'}}>Toutes les offres*</span> dans un seul <span style={{textDecoration: 'none'}}>email</span> 🔥</h1>
            <h2 style={{textAlign:'right', color: '#1c2d35', fontSize: 22, fontWeight: 300, marginRight: 55 }} id='mobileHide'>*dernière mise à jour {day}/{n}/{y}</h2>

            <BS.Row style={{display: 'flex', flexWrap: 'wrap'}} id='newsletter'>
              <BS.Col md={6}>
                <BS.Form.Control size="lg" value={email} onChange={changeEmailHandler} id="newsletterEmail" style={{
                padding: 26,
                // width: 342,
                height: 70,
                fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
                fontSize: '1.25rem',
                border: '1px solid #ced4da',
                borderRadius:'4px',
                boxShadow: '0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%)',
              }} name="EMAIL"
                type="text" className="subscribe-email" placeholder={props.email || 'indiquez votre email...'} onFocus={e => setEmail('')}/>
              </BS.Col> 
              <BS.Col md={6} className={shake ? null : 'shake'} id='newsletterButton'>
                <BS.Button id="comparateurButton" type="submit" className="btn-lg btn-primary" style={{height: 70, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)',
              borderRadius: '0.3rem',
              fontFamily:'-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
              border: '1px solid transparent',
              fontSize: '24px',
              lineHeight: '1.5',
              padding: '0.5rem 1rem',
              color: 'white',
              cursor: 'pointer'
              }}>{props.button || "Envoyez-moi la liste "} <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></BS.Button>
              </BS.Col>
        
            </BS.Row>
           <h4 style={{textAlign:'center'}} id='rejoignez'>Rejoignez les 30 000 membres qui ont déjà profités des offres!</h4>
           
            </BS.Form.Group>
            </form>
          </div>}

      </div>
      </BS.Container>
      
      </>
    )
}

export default BankBank